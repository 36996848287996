import { ReactElement, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Badge, Button, Card, Popover, Spinner } from '@myeh/design-system'
import useSize from '@react-hook/size'

import useUserPermissions from '../../../hooks/useUserPermissions'
import { MaintenancePageStatus } from '../../../models/MaintenanceStatus'
import Right from '../../../models/Right'
import { getMaintenancePageStatus, postMaintenancePageStatus } from '../../../services/MaintenancePageService'
import { getEnvironment } from '../../../utils/env'
import { PageTitle } from '../../atoms/PageTitle/PageTitle'
import { SimpleModal } from '../../molecules/SimpleModal/SimpleModal'

import style from './MaintenancePage.module.scss'

const text = {
  display: 'Display',
  active: 'Active',
  inactive: 'Inactive',
  activeDescription: 'The maintenance page is activated. All MyEH users are not able to access the application',
  inactiveDescription: 'The maintenance page is deactivated',
  deploymentDescription:
    'A deployment is ongoing. The status of the maintenance page may take a few minutes to be reflected on the application',
  buttonActivate: 'Activate maintenance page',
  buttonDeactivate: 'Deactivate maintenance page',
  buttonDisabledDueToRights: "You don't have the necessary rights to perform this action.",
  errorMessage: 'We are currently unable to display the status of the maintenance page. Please try again later.'
}

const deactivateModalText = {
  validationTitle: 'Are you sure you want to deactivate the maintenance page?',
  validationDescription: `You are about to deactivate the maintenance page in ${getEnvironment()}`,
  successTitle: 'Maintenance page deactivation process has started',
  errorTitle: 'Maintenance page deactivation process failed',
  errorDescription: 'We are doing our best so you can deactivate the maintenance page. Please try again later.'
}

const activateModalText = {
  validationTitle: 'Are you sure you want to display the maintenance page?',
  validationDescription: `You are about to activate the maintenance page for the MyEH ${getEnvironment()} environment.
    Once the maintenance page is activated, all MyEH users will be unable to access the application.`,
  successTitle: 'Maintenance page activation process has started',
  errorTitle: 'Maintenance page deactivation process failed',
  errorDescription: 'We are doing our best so you can deactivate the maintenance page. Please try again later.'
}

const DEFAULT_REFETCH_INTERVAL = 15000
const DEPLOYING_REFETCH_INTERVAL = 5000

export function MaintenancePage(): ReactElement {
  const { oidcUser } = useReactOidc()

  const [openedModal, setOpenedModal] = useState<'success' | 'error' | 'validation' | 'none'>('none')
  const [modalConfig, setModalConfig] = useState(activateModalText)

  const target = useRef(null)
  const [width] = useSize(target)
  const isSmall = width < 800

  const { hasPermission } = useUserPermissions()
  const canWrite = hasPermission(Right.MAINTENANCE_WRITE)

  const { data, isLoading, isError, refetch } = useQuery(
    'MaintenanceStatus',
    () => getMaintenancePageStatus(oidcUser),
    {
      refetchInterval: (data) => {
        if (!data) return false
        if (data.isDeploymentInProgress) return DEPLOYING_REFETCH_INTERVAL
        return DEFAULT_REFETCH_INTERVAL
      },
      retry: 1
    }
  )

  const { mutate, isLoading: isUpdateLoading } = useMutation(
    (body: MaintenancePageStatus) => postMaintenancePageStatus(oidcUser, body),
    {
      onSuccess: () => {
        refetch()
        setOpenedModal('success')
      },
      onError: () => {
        setOpenedModal('error')
      }
    }
  )

  const handleCloseModal = () => {
    setOpenedModal('none')
  }

  const handleOpenModal = () => {
    setModalConfig(isMaintenancePage ? deactivateModalText : activateModalText)
    setOpenedModal('validation')
  }

  const { isMaintenancePage, isDeploymentInProgress } = data || {}

  const message = isDeploymentInProgress
    ? text.deploymentDescription
    : isMaintenancePage
    ? text.activeDescription
    : text.inactiveDescription

  return (
    <div ref={target}>
      <PageTitle title='Maintenance page' />
      <div className='pa4'>
        <div className={style.intro} data-testid='maintenance-intro'>
          On this page you can review the position of the maintenance page for the environment your are currently
          connected to. Should you have the relevant rights, you can activate or deactivate the maintenance page on
          MyEH. <b>Once activated, the page is displayed to all MyEH users.</b>
        </div>
        {isLoading ? (
          <div className={style.spinner}>
            <Spinner size='large' />
          </div>
        ) : (
          <Card className={style.card}>
            <div className={style.content}>
              {isError ? (
                <div data-testid='maintenance-error'>{text.errorMessage}</div>
              ) : (
                <>
                  <b>{text.display}</b>
                  <div data-testid='maintenance-status'>
                    <Badge type={isMaintenancePage ? 'positive' : 'default'}>
                      {isMaintenancePage ? text.active : text.inactive}
                    </Badge>
                  </div>
                  <div className={style.description}>{message}</div>
                  <Popover trigger={canWrite ? 'manual' : 'hover'} direction='top'>
                    <Popover.Toggle>
                      <Button
                        onClick={handleOpenModal}
                        className={style.button}
                        disabled={!canWrite || isDeploymentInProgress}
                        size='small'
                        data-testid='activation-button'
                      >
                        <span className={isSmall ? style['small-button-text'] : style['button-text']}>
                          {isMaintenancePage ? text.buttonDeactivate : text.buttonActivate}
                        </span>
                      </Button>
                    </Popover.Toggle>
                    <Popover.Content>{text.buttonDisabledDueToRights}</Popover.Content>
                  </Popover>
                </>
              )}
            </div>
          </Card>
        )}
      </div>
      <SimpleModal
        open={openedModal === 'validation' && !isDeploymentInProgress}
        onClose={handleCloseModal}
        title={modalConfig.validationTitle}
        body={modalConfig.validationDescription}
        footer={
          <>
            <Button
              size='small-medium'
              variant='secondary'
              className='mr3'
              onClick={handleCloseModal}
              disabled={isUpdateLoading}
              data-testid='dismiss-button-validation-modal'
            >
              No, dismiss
            </Button>
            <Button
              size='small-medium'
              variant='primary'
              onClick={() => mutate({ isMaintenancePage: !isMaintenancePage })}
              disabled={isUpdateLoading}
              data-testid='continue-button-validation-modal'
            >
              {isUpdateLoading ? <Spinner aria-label='spinner' size='medium' /> : 'Yes, continue'}
            </Button>
          </>
        }
        showCloseIcon={!isUpdateLoading}
        hideOnClickOutside={!isUpdateLoading}
        testId='validation-modal'
      />

      <SimpleModal
        open={openedModal === 'success'}
        onClose={handleCloseModal}
        titleIcon='success'
        title={modalConfig.successTitle}
        footer={
          <Button
            size='small-medium'
            variant='primary'
            onClick={() => {
              setOpenedModal('none')
            }}
            data-testid='ok-button-success-modal'
          >
            OK
          </Button>
        }
        testId='success-modal'
        showCloseIcon={false}
        hideOnClickOutside={false}
      />

      <SimpleModal
        open={openedModal === 'error'}
        onClose={handleCloseModal}
        titleIcon='error'
        title={modalConfig.errorTitle}
        body={modalConfig.errorDescription}
        footer={
          <Button size='small-medium' variant='primary' onClick={handleCloseModal} data-testid='ok-button-error-modal'>
            OK
          </Button>
        }
        testId='error-modal'
      />
    </div>
  )
}
