const environmentMap: { [key: string]: string } = {
  test: 'test',
  int: 'integration',
  uatm: 'uat-m',
  uatr: 'uat-r',
  prod: 'production',
  proddr: 'prod DR'
}

export const getEnvironment = () => {
  const env = process.env.REACT_APP_ENVIRONMENT || ''
  return environmentMap[env] || ''
}

const subEnvironmentMap: { [key: string]: string[] } = {
  test: ['test', 'teststaging'],
  int: ['int', 'intr', 'intstaging'],
  prod: ['prodactive', 'prodhidden']
}

export const getSubEnvironments = () => {
  const env = process.env.REACT_APP_ENVIRONMENT || ''
  return subEnvironmentMap[env] || [env]
}
